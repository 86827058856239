import React from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import { Redirect, Switch, Route } from 'react-router-dom';
import { Footer, Header, Sidebar } from '../../components';
import dashboardRoutes from '../../routes/dashboard.jsx';
import userRoutes from '../../routes/user';
import { connect } from 'react-redux';

let ps;

class Dashboard extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          routes={dashboardRoutes}
          userRoutes={userRoutes}
          {...this.props}
        />
        <div className="main-panel" ref="mainPanel">
          <Header {...this.props} />

          <Switch>
            {userRoutes.map((prop, key) => {
              if (prop.collapse) {
                return prop.views.map((prop2, key2) => {
                  return (
                    <Route
                      path={prop2.path}
                      component={prop2.component}
                      key={key2}
                    />
                  );
                });
              }
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
            {dashboardRoutes.map((prop, key) => {
              if (prop.collapse) {
                return prop.views.map((prop2, key2) => {
                  return (
                    <Route
                      path={prop2.path}
                      component={prop2.component}
                      key={key2}
                    />
                  );
                });
              }
              if (prop.redirect)
                return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>

          {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf('full-screen-maps') !==
          -1 ? null : (
            <Footer fluid />
          )}
        </div>
      </div>
    );
  }
}

export default connect(({ fb }) => ({ fb }))(Dashboard);
